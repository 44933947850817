html, body, .layoutWrap {
    height: 100%;
    position: relative;
    overflow-x: hidden !important;
    background: #FFFFFF;
    color: #000000 !important;
}

.f12 {
    font-size: 12px !important;
}

.f20 {
    font-size: 20px;
}

.pl0 {
    padding-left: 0 !important;
}

.mb5 {
    margin-bottom: 5px !important;
}

.pt0 {
    padding-top: 0 !important;
}

.pb0 {
    padding-bottom: 0 !important;
}

.pd16 {
    padding: 16px !important;
}

.mb10 {
    margin-bottom: 10px !important;
}

.ml10 {
    margin-left: 10px !important;
}

.displayInlineFlex {
    display: inline-flex;
}

.displayInlineFlex sup {
    margin-top: -10px !important;
}

input:disabled, .MuiFilledInput-root.Mui-disabled,
div.Mui-disabled.Mui-disabled,
textarea.Mui-disabled.Mui-disabled {
    color: #263238 !important;
    background-color: #d4d4d4;
}

.Mui-disabled {
    background: #f3f3f3;
}


@media (max-width: 992px) {
    .displayInlineFlex sup {
        margin-top: -5px !important;
    }
}

ul {
    float: left;
    width: 100%;
    padding-left: 0;
}

.f14 {
    font-size: 14px !important;
}

.textHighLight {
    color: #62DD97;
}

.mb0 {
    margin-bottom: 0 !important;
}

.mb30 {
    margin-bottom: 30px !important;
}

.w100 {
    width: 100% !important;
}

.h100 {
    height: 100% !important;
}

.f12 {
    font-size: 12px !important;
}

.muiSelect {
    height: 66px !important;
}

.colorRed {
    color: red;
}

sup {
    top: -15px;
}

.mt30 {
    margin-top: 30px !important;
}

.mr10 {
    margin-right: 10px !important;
}

.mr15 {
    margin-right: 15px !important;
}

.ml15 {
    margin-left: 15px !important;
}

.ml5 {
    margin-left: 5px !important;
}

.cursor {
    cursor: pointer;
}

.pointer a {
    cursor: pointer !important;
    text-decoration: underline !important;
}

.pointer a:hover {
    text-decoration: none !important;
}

.textCenter {
    text-align: center !important;
}

.colorOrg {
    color: #D6A107;
}

.pd15 {
    padding: 15px !important;
}

.mt0 {
    margin-top: 0 !important;
}

.mt10 {
    margin-top: 10px !important;
}

.mr15 {
    margin-right: 15px !important;
}

.ml30 {
    margin-left: 30px !important;
}

.mt15 {
    margin-top: 15px !important;
}

.mt40 {
    margin-top: 40px !important;
}

.mt45 {
    margin-top: 45px !important;
}

.mb20 {
    margin-bottom: 20px !important;
}

.mb40 {
    margin-bottom: 40px !important;
}

.mb15 {
    margin-bottom: 15px !important;
}

.mr30 {
    margin-right: 30px !important;
}

.textPrimary {
    color: #01305F !important;
}

.textLightBlack {
    color: #000000 !important;
}

.textLightGray {
    color: #666666 !important;
}

.cardPadding {
    padding: 20px;
}

.f28 {
    font-size: 28px !important;
}

.textLight {
    color: #666666;
}

.floatLeft {
    float: left;
}

.floatRight {
    float: right;
}

.mt20 {
    margin-top: 20px !important;
}

.clearFix {
    clear: both;
}

strong, b {
    font-weight: bold !important;
}

a {
    color: #01305F;
    text-decoration: none;
}

.textWhite {
    color: #FFFFFF !important;
}

.floatRight {
    float: right;
}

.positionR {
    position: relative !important;
    z-index: 1;
}

.bgRed {
    background: red !important;
    border: none;
    color: #FFFFFF !important;
}

.deleteIcon {
    display: none !important;
    color: red;
    margin-left: 20px;
}

.accordionWrap:hover .deleteIcon {
    display: block !important;
    margin-top: 5px !important;
    position: absolute;
    right: 15px;
}

.react-multiple-carousel__arrow--left {
    left: calc(0% + 0px) !important;
    z-index: 0 !important;
}

.react-multiple-carousel__arrow--right {
    right: calc(0% + 0px) !important;
    z-index: 0 !important;
}

.react-multiple-carousel__arrow {
    border-radius: 0px !important;
    background: #FFFFFF !important;
    color: #01305F;
    min-width: 50px !important;
    min-height: 130px !important;
}
.linkBtn.disable{
    color: rgba(0, 0, 0, 0.26);
    box-shadow: none;
    background-color: rgba(0, 0, 0, 0.12);
    pointer-events: none
}
.linkBtn.disable:hover{
    cursor: none;
    color: rgba(0, 0, 0, 0.26);
    box-shadow: none;
    background-color: rgba(0, 0, 0, 0.12);
}
@media (max-width: 768px) {
    .react-multiple-carousel__arrow {
        min-height: 90px !important;
        top: 120px;
    }

    .sectionPD {
        padding: 10% 0 12% !important;
    }
}

.react-multiple-carousel__arrow::before {
    font-size: 40px !important;
    color: #000 !important;
}


/*
 *  STYLE 2
 */

div::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    background-color: transparent;
}

div::-webkit-scrollbar {
    width: 16px;
    height: 8px;
    background-color: transparent;
}

div::-webkit-scrollbar-thumb {
    border-radius: 4px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: #cfcfcf;
}

input:-internal-autofill-selected {
    background-color: transparent !important;
    color: #424242 !important;
}

.MuiAppBar-positionFixed {
    top: 39px !important;
}

.MuiAppBar-positionFixed.iliaLogo {
    top: 0 !important;
}

.accordionMobile {
    padding-bottom: 250px;
}

@media (max-width: 768px) {
    .MuiAppBar-positionFixed {
        top: 44px !important;
    }
}

.showEditIconForBg, .showEditIconForVideo {
    position: relative;
    float: left;
    width: 100%;
    /*height: 100%;*/
    border: 1px solid transparent;
    border-radius: 6px;
    -webkit-transition: opacity 0.5s ease-in-out;
    -moz-transition: opacity 0.5s ease-in-out;
    -ms-transition: opacity 0.5s ease-in-out;
    -o-transition: opacity 0.5s ease-in-out;
    transition: opacity 0.5s ease-in-out;
}

.showEditIconForBg .editBtnBg {
    background: #FFFFFF !important;
    color: #01305F !important;
}

.showEditIconForBtn {
    position: relative;
}

.showEditIconForBg .editBtnBg, .showEditIconForVideo .editBtnVideoBlue, .showEditIconForBtn .editBtnBtnBlue {
    z-index: 99;
    width: 40px;
    height: 40px;
    cursor: pointer;
    top: 150px !important;
    right: 20px !important;
    left: inherit !important;
    clear: both;
    display: none !important;
    position: absolute;
}

.showEditIconForBtn .editBtnBtnBlue {
    width: 30px !important;
    height: 30px !important;
    top: 0 !important;
}

.showEditIconForVideo:hover .editBtnVideoBlue {
    background: #01305F !important;
    color: #FFFFFF !important;
    top: 0 !important;
    right: 0 !important;
    left: 0 !important;
    margin: 0 auto;
}

.showEditIconForBtn:hover .editBtnBtnBlue {
    right: 0 !important;
    top: 0 !important;
    background: #01305F !important;
    color: #FFFFFF !important;
}

.showEditIconForBg:hover .editBtnBg, .showEditIconForVideo:hover .editBtnVideoBlue, .showEditIconForBtn:hover .editBtnBtnBlue {
    display: block !important;
}

.borderRadius10 iframe {
    border-radius: 10px !important;
}

@media (max-width: 767px) {
    .showEditIconForBg:hover .editBtnBg, .showEditIconForVideo:hover .editBtnVideoBlue {
        top: 74px !important;
        right: 4px !important;
        width: 30px;
        height: 30px;
    }
}

.boxItem .sectionDelete {
    color: red;
    position: absolute;
    right: 10px;
    top: 5px;
    display: none;
}

.boxItem:hover .sectionDelete {
    display: block;
}

.wAuto {
    width: auto !important;
}

.boxItem:before {
    content: "";
    position: absolute;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    top: calc(2px / -1);
    left: calc(2px / -1);
    background: linear-gradient(to right, #01305F 0%, #01305F 100%), linear-gradient(to top, #01305F 50%, transparent 50%), linear-gradient(to top, #01305F 50%, transparent 50%), linear-gradient(to right, #01305F 0%, #01305F 100%), linear-gradient(to left, #01305F 0%, #01305F 100%);
    background-size: 100% 2px, 2px 200%, 2px 200%, 0% 2px, 0% 2px;
    background-position: 50% 100%, 0% 0%, 100% 0%, 100% 0%, 0% 0%;
    background-repeat: no-repeat, no-repeat;
    transition: transform 0.3s ease-in-out, background-position 0.3s ease-in-out, background-size 0.3s ease-in-out;
    transform: scaleX(0) rotate(0deg);
    transition-delay: 0.5s, 0.3s, 0s;
    border-radius: 6px !important;
}

.boxItem:hover:before {
    background-size: 200% 2px, 2px 400%, 2px 400%, 55% 2px, 55% 2px;
    background-position: 50% 100%, 0% 100%, 100% 100%, 100% 0%, 0% 0%;
    transform: scaleX(1) rotate(0deg);
    transition-delay: 0s, 0.3s, 0.5s;
    cursor: pointer;
}

@media (max-width: 1024px) {
    .displayNone1024 {
        display: none !important;
    }
}


ul.socialIcons {
    display: flex;
    width: auto;
}

ul li.socialIcon {
    width: auto;
    padding: 0;
    margin-right: 10px;
}

.showEditIcon, .showEditIconBlue {
    clear: both;
    position: relative;
    border: 1px solid transparent;
    border-radius: 6px;
    -webkit-transition: opacity 0.5s ease-in-out;
    -moz-transition: opacity 0.5s ease-in-out;
    -ms-transition: opacity 0.5s ease-in-out;
    -o-transition: opacity 0.5s ease-in-out;
    transition: opacity 0.5s ease-in-out;
    display: flow-root;
}

.showEditIcon:hover, .showEditIconBlue:hover {
    border: 1px solid #cfcfcf;
    cursor: pointer;
}

.editBtn {
    background: #01305F !important;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    color: #FFFFFF !important;
    cursor: pointer;
}

.editBtn svg {
    font-size: 20px;
}

.editBtn, .showEditIcon .editBtn, .showEditIconBlue .editBtn {
    display: none !important;
}

.showEditIcon:hover .editBtn, .showEditIconBlue:hover .editBtn {
    display: block !important;
    font-size: 26px;
    float: right;
    top: 70px;
    position: absolute;
    right: 30px;
    z-index: 999;
}

.showEditIcon:hover .editBtn {
    background: #FFFFFF !important;
    top: -7px !important;
    color: #01305F !important;
    right: -5px !important;
    left: inherit !important;
}

.showEditIconBlue:hover .editBtn {
    background: #01305F !important;
    top: -18px !important;
    color: #FFFFFF !important;
    right: -14px !important;
    left: inherit !important;
}

#mui-rte-editor-container {
    margin: 0 !important;
    float: left;
    width: 100%;

}

#mui-rte-container {
    margin: 0 !important;
    padding: 0 !important;
}

#mui-rte-editor {
    float: left;
    width: 100%;
}

#mui-rte-toolbar div {
    margin-right: 5px;
    cursor: pointer;
}

#mui-rte-Save-button {
    float: right;
    padding: 0;
    color: #01305F;
    top: 2px;
}

.MuiDialog-paperWidthMd {
    min-height: 250px;
}

.forBg .MuiPaper-root.MuiDrawer-paper, .menuStyle .MuiPaper-root.MuiDrawer-paper {
    margin-top: 90px;
    padding-top: 70px;
    padding-bottom: 50px;
}

.forBg.menuStyle .MuiPaper-root.MuiDrawer-paper {
    background-image: url("../public/images/img-woman-executive-ipad.jpg");
    background-position: top right;
    background-repeat: no-repeat;
    background-size: contain;

}

@media (max-width: 1400px) {
    .forBg.menuStyle .MuiPaper-root.MuiDrawer-paper {
        padding-bottom: 110px
    }
}

.accordionMobile .MuiBackdrop-root {
    top: 90px !important;
}

.MuiCardContent-root.getStartedContent {
    padding: 30px !important;
}

img.scrollIcon {
    position: relative;
    top: 20px;
    left: -15px;
    -webkit-animation: mover 0.5s infinite alternate;
    animation: mover 0.5s infinite alternate;
}

img.scrollIcon {
    -webkit-animation: mover 0.5s infinite alternate;
    animation: mover 0.5s infinite alternate;
}

.marginNone .MuiAccordionSummary-content {
    margin: 0 !important;
}

.boxShadowNone {
    box-shadow: none !important;
}

.MuiAccordionSummary-content {
    margin: 40px 0 !important;
}

.MuiAccordion-rounded {
    border-radius: 6px !important;
}

@-webkit-keyframes mover {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-10px);
    }
}

@keyframes mover {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-10px);
    }
}

.MuiPaper-root.MuiDrawer-paper.MuiDrawer-paperAnchorLeft {
    z-index: 99999 !important;
}

@media (max-width: 1365px) {
    .MuiPaper-root.MuiDrawer-paper.MuiDrawer-paperAnchorLeft {
        top: 100px !important;
    }
}

@media (max-width: 768px) {
    .MuiPaper-root.MuiDrawer-paper.MuiDrawer-paperAnchorLeft {
        top: 110px !important;

    }
}

@media (max-width: 992px) {
    #mui-rte-toolbar div {
        margin-bottom: 5px;
    }

    .getStartedContent:hover .editBtn, .showEditIcon:hover .editBtn {
        top: 40px;
        right: 12px;
    }
}

.MuiInputLabel-outlined {
    /*z-index: inherit !important;*/
    transform: translate(14px, 14px) scale(1) !important;
}

.MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, -6px) scale(0.75) !important;
}

.public-DraftEditor-content div ul {
    float: left;
    margin: 0;
    padding: 0;
}

/* .public-DraftEditor-content div ul li { */ /* commented because need to discuss with designer */

.public-DraftEditor-content div ul li {
    background: url("../public/images/tickIcon.png") no-repeat;
    width: 100%;
    height: 20px;
    float: left;
    padding: 5px 35px;
    list-style: none;
    display: inline-table;
    background-position: 0 10px;
    color: rgba(0, 0, 0, 1);
    font-weight: normal;
}

.public-DraftStyleDefault-ul li {
    background: url("../public/images/tickIcon.png") no-repeat;
    width: 100%;
    height: 20px;
    float: left;
    padding: 5px 35px;
    list-style: none;
    display: inline-table;
    background-position: 0 10px;
    color: rgba(0, 0, 0, 1);
    font-weight: normal;
}

.cardActive:hover, .cardActive {
    border: 2px solid #01305F;
}

.muiSelect {
    text-align: left !important;
}

.active, .bgLightWhite.active {
    background-color: #102380 !important;
    color: #FFFFFF !important;
}

.MuiAccordionSummary-root.Mui-focusVisible {
    background-color: transparent !important;
}

.formControlCheckbox {
    vertical-align: middle !important;
    align-items: center !important;
}

.formControlCheckbox span:nth-child(1) {
    padding: 1px 10px !important;
}

.formControlCheckbox span:nth-child(1) span {
    padding: 1px 0 !important;
}

.graphWrap {
    border: 1px solid #e6e6e6;
    float: left;
    width: 100%;
    border-radius: 8px;
}

.graphMap {
    width: 100%;
    height: 400px;
    margin: 0 auto;
}

.graphWidth {
    height: 600px !important;
}

/*.ContactMe {*/
/*    background: url("../components/KaiZen/Estimation/img-blurred-bg.jpg") no-repeat;*/
/*    background-size: cover;*/
/*    width: 100%;*/
/*    height: 100%;*/
/*    float: left;*/
/*    padding: 12% 6%;*/
/*    max-width: 100%;*/
/*    background-position: center bottom;*/
/*    margin-top: 30px !important;*/
/*}*/

.flexContainer li {
    float: left;
    width: auto;
}

.flexContainer li div:first-child {
    min-width: 20px;
}

.flexContainer li div svg {
    width: 40px;
    height: 36px;
}

.colorBlue {
    color: #3871EC !important;
    font-size: 30px;
}

.colorYellow {
    color: #EDB339 !important;
    font-size: 30px;
}

.colorGreen {
    color: #28B127 !important;
    font-size: 30px;
}

.flexContainer li div span {
    font-size: 12px !important;
}

#walktour-tooltip-container:focus-visible {
    outline: transparent !important;
}

.rectangleShapeBefore {
    position: absolute;
    top: -2px;
    width: 100%;
    -webkit-transform: rotate(
            180deg);
    transform: rotate(
            180deg);
    left: 0;
    right: 0;
}

.MuiDialog-paperScrollPaper #mui-rte-editor {
    max-height: 200px;
    overflow: auto;
    float: left;
    width: 100%;
}

.chrome-picker {
    width: 100% !important;
    margin: 0 auto 20px !important;
}

.bgLightWhite {
    background: #e0e0e0 !important;
    padding: 1px 15px !important;
    border-radius: 16px !important;
    margin-right: 5px !important;
}

.selectBox {
    background: #e0e0e0;
    border-radius: 16px !important;
    font-size: 0.8125rem;
}

.selectBox div {
    padding: 0 18px !important;
    font-size: 0.8125rem;
}

.scroll {
    background: #01305F !important;
    color: #FFFFFF !important;
    border-radius: 0 !important;
    top: 50%;
    position: absolute !important;
    width: 50px !important;
    height: 100px !important;
}

.scrollRight {
    right: -65px !important;
}

.scrollLeft {
    left: -50px !important;
}

.tableCellhover {
    position: relative;

}

.tableCellhover .Iconhover {
    display: none;
    color: red;
    position: absolute;
    right: 0;
    cursor: pointer;
    top: 4px;
}

.tableCellhover:hover .Iconhover {
    display: block;
}

.heroVideoWrap iframe {
    height: 500px !important;
}

.MuiPaper-root {
    color: #000000 !important;
}

.MuiAlert-filledError, .MuiAlert-filledSuccess {
    color: #FFFFFF !important;
}


.cursor div {
    cursor: pointer !important;
}

.bgLightGray {
    background: #F8F8F8 !important;
}

.returnRateBox {
    height: 72px;
    width: 230px;
    border: 1px solid #3DDB93;
    border-radius: 8px;
    float: right;
    font-size: 14px;
    text-align: center;
    color: rgba(0, 0, 0, 0.87);
    font-weight: bold;
    letter-spacing: 0;
    line-height: 16px;
    padding: 12px;
}

.returnRateBox strong {
    font-size: 28px;
    line-height: 33px;
    color: #3DDB93;
    width: 100%;
    display: inline-block;
    font-family: 'Roboto', sans-serif;
}

.benefitsCard {
    box-shadow: none;
    overflow-x: auto;
}


.benefitsCard ul li {
    padding-top: 0;
    padding-bottom: 0;
}

#mui-rte-link-control-button {
    float: right;
    padding: 0;
}

.forRealButton button {
    padding: 6px 25px !important;
    border-radius: 50px !important;
}

.editPageMeta {
    position: absolute !important;
    right: 15px;
    top: 10px;
}

@media (min-width: 1281px) {
    .kaiZenHero, .kaiZenHero .react-player__preview {
        float: right;
    }
}


.kaiZenHero .react-player__preview {
    width: 100% !important;
    height: 388px !important;
    background-position: top center !important;
    background-size: contain !important;
    background-repeat: no-repeat !important;
}

.backGroundImageCropper .ReactCrop__crop-selection {
    border-image-source: none;
}

.pd0 {
    padding: 0 !important;
}

.sectionPD {
    padding: 100px 0 120px;
    position: relative;
    clear: both;
}

.sectionPDMini {
    padding: 2% 15px;
    position: relative;
    clear: both;
}

.heroContent {
    padding: 60px 15px;
    position: relative;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    margin-bottom: 40px;
    align-items: center;
    margin-top: 150px;
    vertical-align: middle;
    display: flex;
    min-height: 785px;

}

.height450 {
    min-height: 450px !important;
}

@media (max-width: 992px) {
    .height450 {
        min-height: 200px !important;
    }
}

@media (min-width: 2000px) {
    .heroContent {
        padding: 80px 15px;
    }

}

@media (max-width: 1500px) {
    .heroContent {
        margin-top: 150px;
    }

}

@media (max-width: 992px) {
    .heroContent {
        padding: 0;
        margin-top: 70px;

    }

}

@media (max-width: 768px) {
    .heroContent {
        margin-top: 106px;
        padding: 20px 0;
    }

}

.title, .titleCenter {
    position: relative
}

.titleCenter:before {
    content: '';
    position: absolute;
    height: 2px;
    width: 150px;
    top: -20px;
    border-radius: 4px;
    background: #D6A107;
    margin: 0 auto;
    left: 0;
    right: 0;
}

.title::before {
    content: '';
    position: absolute;
    height: 2px;
    width: 150px;
    top: -30px;
    border-radius: 4px;
    background: #D6A107;
}


.colorBg {
    background: #F7F9FB;
    padding: 100px 15px 120px;
    position: relative;
    clear: both;
}

@media (max-width: 992px) {
    .colorBg {
        padding: 50px 0;
        background: transparent;
    }
}

.getStarted {
    text-align: center;
    position: relative;
    overflow: visible !important;
}

.getStarted.MuiPaper-rounded {
    border-radius: 8px;
}

.MuiPaper-rounded {
    border-radius: 12px;
}

.getStarted::before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-top: 15px solid transparent;
    border-right: 20px solid #ffffff;
    border-bottom: 15px solid transparent;
    left: -18px;
    top: 44%;
}

.MuiCardContent-root {
    padding: 24px !important;
}

@media (max-width: 992px) {
    .getStarted::before {
        top: -19px;
        left: 42%;
        right: 0;
        border-top: 0;
        border-left: 18px solid transparent;
        border-right: 18px solid transparent;
        border-bottom: 20px solid #FFFFFF;
    }

    .post__profile__channel {
        float: left;
        position: relative;
        width: 100%;
    }
}


@media (max-width: 1282px) {
    .benefitsCardContent ul li:nth-child(2) div:nth-child(1), .benefitsCardContent ul li:nth-child(3) div:nth-child(1) {
        padding: 0;
    }

    .benefitsCardContent ul li:nth-child(2) div:nth-child(2) .valueText, .benefitsCardContent ul li:nth-child(3) div:nth-child(2) .valueText {
        margin-left: 56px;
    }

    .benefitsCardContent ul li:nth-child(2) div:nth-child(2), .benefitsCardContent ul li:nth-child(3) div:nth-child(2) {
        position: unset;
        margin-top: 10px;
        width: 100%;
        padding-right: 50px;
    }

    ul.tabsContentList li div:nth-child(1) {
        padding: 0;
    }

    .kaiZenHero {
        margin: 0 auto;
        background: transparent;
    }

    .kaiZenHero iframe {
        width: 100% !important;
    }
}

@media (max-width: 1550px) {
    .graphWrap {
        width: 100% !important;
        overflow-x: auto;
        overflow-y: hidden;
    }

}

@media (max-width: 1356px) {
    .kaiZenHero .react-player__preview {
        margin: 0 auto;
        width: 70% !important;
    }
}

@media (max-width: 992px) {
    .kaiZenHero .react-player__preview {
        width: 100% !important;
        margin: 0 auto;
        background-size: contain !important;
    }

    .kaiZenHero {
        width: 100% !important;;
    }
}

@media (max-width: 767px) {
    .editPageMeta {
        top: 50px;
    }

    .kaiZenHero .react-player__preview {
        width: 100% !important;
        height: 200px !important;
    }

}

@media (max-width: 1500px) {
    .scroll {
        top: 47%;
        width: 40px !important;
        height: 60px !important;
        z-index: 9;
    }

    .scroll.scrollRight.mr15 {
        margin-right: 0 !important;
    }

    .scrollLeft {
        left: 0 !important;
    }

    .scrollRight {
        right: 0 !important;
    }
}

@media (max-width: 768px) {
    .scroll {
        top: 47%;
        width: 30px !important;
        height: 30px !important;
        z-index: 9;
    }

    .scroll.scrollRight.mr15 {
        margin-right: 0 !important;
    }
}


/*-- carouselStyle Imported from module 08-03-2022*/

@font-face {
    font-family: "revicons";
    font-display: swap;
    src: url("Iconfont/revicons.woff") format('woff'), url("Iconfont/revicons.ttf") format('ttf'), url("Iconfont/revicons.eot") format('ttf')
}

.react-multi-carousel-list {
    display: flex;
    align-items: center;
    overflow: hidden;
    position: relative
}

.react-multi-carousel-track {
    width: auto;
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    position: relative;
    transform-style: preserve-3d;
    backface-visibility: hidden;
    will-change: transform, transition
}

.react-multiple-carousel__arrow {
    position: absolute;
    outline: 0;
    transition: all .5s;
    border-radius: 35px;
    z-index: 1000;
    border: 0;
    background: rgba(0, 0, 0, 0.5);
    min-width: 43px;
    min-height: 43px;
    opacity: 1;
    cursor: pointer
}

.react-multiple-carousel__arrow:hover {
    background: rgba(0, 0, 0, 0.8)
}

.react-multiple-carousel__arrow::before {
    font-size: 20px;
    color: #fff;
    display: block;
    font-family: revicons;
    text-align: center;
    z-index: 2;
    position: relative
}

.react-multiple-carousel__arrow:disabled {
    cursor: default;
    background: rgba(0, 0, 0, 0.5)
}

.react-multiple-carousel__arrow--left {
    left: calc(4% + 1px)
}

.react-multiple-carousel__arrow--left::before {
    content: "\e824"
}

.react-multiple-carousel__arrow--right {
    right: calc(4% + 1px)
}

.react-multiple-carousel__arrow--right::before {
    content: "\e825"
}

.react-multi-carousel-dot-list {
    position: absolute;
    bottom: 0;
    display: flex;
    left: 0;
    right: 0;
    justify-content: center;
    margin: auto;
    padding: 0;
    list-style: none;
    text-align: center
}

.react-multi-carousel-dot button {
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    opacity: 1;
    padding: 5px 5px 5px 5px;
    box-shadow: none;
    transition: background .5s;
    border-width: 2px;
    border-style: solid;
    border-color: grey;
    padding: 0;
    margin: 0;
    margin-right: 6px;
    outline: 0;
    cursor: pointer
}

.react-multi-carousel-dot button:hover:active {
    background: #080808
}

.react-multi-carousel-dot--active button {
    background: #080808
}

.react-multi-carousel-item {
    transform-style: preserve-3d;
    backface-visibility: hidden;
    max-width: 488px;
}

@media all and (-ms-high-contrast: none),(-ms-high-contrast: active) {
    .react-multi-carousel-item {
        flex-shrink: 0 !important
    }

    .react-multi-carousel-track {
        overflow: visible !important
    }
}

/*-- carouselStyle Imported from module End*/

span.no-title {
    margin-top: 27px;
}

a.border-bottom-link:hover {
    border-bottom: 1px solid #01305F;
}

div::-webkit-scrollbar-track {
    border-radius: 0.125rem;
    background-color: lightgray;
}

div::-webkit-scrollbar {
    width: 0.50rem;
    border-radius: 0.125rem;
}

div::-webkit-scrollbar-thumb {
    border-radius: 0.125rem;
    background-color: gray;
}

@media (min-width: 2000px) {
    .maxWidth2000 {
        max-width: 2000px;
    }
}

.pb5 {
    padding-bottom: 5%;
}

.pb10 {
    padding-bottom: 10px;
}

.mt80 {
    margin-top: 80px !important;
}

.footerVisible, .footerhideen, .bannerVisible, .bannerHideen {
    overflow-y: hidden;
    -webkit-transition: height 1s ease-in-out;
    -moz-transition: height 1s ease-in-out;
    -o-transition: height 1s ease-in-out;
    transition: height 1s ease-in-out;

}

.footerhideen, .bannerHideen {
    height: 0;
}

.bannerHideen {
    bottom: -6px !important;
}

.secondVideos, .video-mask {
    animation: fadeIn 5s;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.footerVisible {
    height: 90px;
}

.bannerVisible {
    height: 116px;
}

.footerLinks li {
    padding-left: 0 !important;
}

@media (max-width:776px) {
    .footerLinks li {
        float: left;
    }
}

.colorBgsection {
    background: #F7F9FB;
    padding: 30px 20px;
    position: relative;
    clear: both;
}

.iconChange {
    border-bottom: 1px solid #cfcfcf;
    border-radius: 0 !important;
}

.iconChange .AddIcon.MuiSvgIcon-root {
    color: #01305F !important;
    font-size: 36px !important;
}

.iconChange {
    border-bottom: 1px solid #cfcfcf;
}

.react-multi-carousel-track li .MuiCardContent-root.alternateBg {
    min-height: 270px;
}

.react-multi-carousel-track li:nth-child(odd) .alternateBg {
    background-color: #EEF3F5;
}

.MuiAppBar-positionFixed, .MuiDialog-root, .MuiPopover-root, #menu-, .popOver ~ div {
    z-index: 1400 !important;
}

.MuiSnackbar-root, .MuiAutocomplete-popper {
    z-index: 1401 !important;
}
.itemHover{
    text-decoration: underline !important;
    transition: 0.5s;
}
.itemHover:hover,.itemHover:hover p{
    text-decoration: underline !important;
    color: #EDB337;
    padding-left: 5px;
    transition: 0.5s;
}

.video-container {
    width: 100%;
    height: 100%;
    min-height: 80vh;
    overflow: hidden;
    position: relative;
    top: 0;
    left: 0;
    background-color: #000;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 150px;
}

.video-container video {
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
}

.video-mask {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    background: rgba(0, 0, 0, .7);
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 120px;
}

.videoHideen {
    display: none;
    position: relative;
}

.videoVisible {
    display: block;
}

/*=====================Header======================*/
header button.menuButton {
    border-bottom: 4px solid transparent !important;
    padding: 45px 17px;
    border-radius: 0;
}

header button.menuButton:hover {
    border-bottom: 4px solid #01305F !important;
    background: transparent !important;
}

.sectionFindAgent {
    background: url("../public/AgentImages/img-map-bg.jpg") no-repeat;
    background-size: cover;
    background-position: center;
    margin-bottom: 0;
    margin-top: 50px;
    align-items: center;
    max-height: 850px;
    min-height: 850px;
    width: 100%;
    position: relative;
}

.agentsImages {
    z-index: 1;
    top: 60%;
    left: 0;
    right: 0;
    margin: 150px auto 0;
    width: 80%;
    max-width: 1920px;
    display: flex;
    justify-content: space-between;

}

.agentsImages span {
    opacity: 0;
    animation-name: fadingin;
    animation-duration: 2s;
    animation-iteration-count: 1;
    transform: translateY(-20px);
    animation-fill-mode: forwards;
}

@keyframes fadingin {
    from {
        transform: translateY(0);
        opacity: 0;
    }
    to {
        transform: translateY(-20px);
        opacity: 1;
    }
}

.agentsImages span:nth-child(1) {
    animation-delay: 0s;
}

.agentsImages span:nth-child(2) {
    animation-delay: 0.4s;
}

.agentsImages span:nth-child(3) {
    animation-delay: 0.7s;
}

.agentsImages span:nth-child(4) {
    animation-delay: 1s;
}

.agentsImages span:nth-child(5) {
    animation-delay: 1.1s
}

.agentsImages span:nth-child(6) {
    animation-delay: 1.2s;
}


.agentsImages span img {
    width: 200px;
}

.pinTwo {
    margin-top: 50px;
}

.pinThree {
    margin-top: -20px;
}

.pinFour {
    margin-top: 100px;
}

.pinFive {
    margin-top: 40px;
}

.pinSix {
    margin-top: -40px;
}

.customBtn {
    padding: 10px 50px;
    color: #fff !important;
    background: #3DDB93 !important;
    border: 2px solid #FFFFFF !important;
    text-align: center;
    border-radius: 50%;
}

.glow {
    animation: shadow 1s infinite;
}

@keyframes shadow {
    0% {
        box-shadow: 0 0 0 10px rgba(61, 219, 147, 0.5);
    }
    100% {
        box-shadow: 0 0 0 35px rgba(61, 219, 147, 0);
    }
}

/*==============AgentHeadercss====================================*/
.agentHeader button:hover {
    border-bottom: 3px solid transparent !important;
}

.wrap {
    padding: 125px 15px 10px !important;
}

@media (max-width: 767px) {
    .wrap {
        padding: 0 !important;
    }

    .MuiCardContent-root {
        padding: 24px 15px !important;
    }
}

.boxShadow {
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.12), 0 1px 8px 0 rgba(0, 0, 0, 0.2) !important;
}

.cardPadding0 {
    padding: 0 !important;
}

.hLine {
    border-bottom: 1px solid #E0E0E0;
    margin: 10px 0;
    float: left;
    width: 100%;
}


.materialContainer {
    display: flex !important;
    cursor: pointer;
    padding: 30px;
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.12), 0 1px 8px 0 rgba(0, 0, 0, 0.2) !important;
}

.materialContainer:hover {
    background: #ededed;
    transition: background-color 0.6s ease;
}

.materialMedia {
    margin: 0 1.5rem 0 0;
    display: flex;
    align-items: center;
}

.materialContent {
    padding: 0 !important;

}

.materialActions {
    position: absolute;
    top: 50px;
    right: 5px;
}

.smallfont span {
    font-size: 12px !important;
}

@media (max-width: 767px) {
    .materialContainer {
        display: inline-block !important;
        cursor: pointer;
    }

    .materialMedia, .materialContent, .materialActions {
        text-align: center;
    }

    .materialActions {
        display: none !important;

    }
}

.labelShrink label.MuiInputLabel-shrink {
    top: -10px !important;
}

input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 36px #FFFFFF inset !important;
}


.actionItemsTabs button {
    border-left: 1px solid #cfcfcf;
    min-width: 48% !important;
    max-width: 300px !important;
    text-transform: capitalize;
    font-size: 16px;
    background: #ffffff;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    border-radius: 4px;
    border: 1px solid #cfcfcf;
}


.actionItemsTabs button svg {
    width: 100%;
    margin: 0 !important;
}

.actionItemsTabs .MuiTabs-indicator {
    background: transparent;
}

.actionItemsTabs button:nth-child(1) {
    border-left: none;
}

.actionItemsTabs button.Mui-selected {
    background: #01305F;
    color: #ffffff;
}

.actionItemsTabs button:hover {
    background: #cccccc;
    color: #000000;
    transition: all 0.5s ease;
}

.actionItemsTabs .MuiTabs-indicator {
    background: transparent;
}

.profileImgWrap {
    line-height: 0;
}

.profileImgWrap img {
    width: 100%;
}

.muiCardContent.userImg {
    padding: 10px !important;
}

.muiCard .muiCardContent.userImg img {
    width: 100%;
    height: auto;
    margin: 0 auto;
}

.userImg {
    position: relative;
}

.userImg p {
    padding: 8px !important;
    float: right;
    position: absolute;
    right: -4px;
    top: -2px;
    background: rgb(57, 113, 236, 0.5);
    margin: 0 !important;
    border-radius: 4px !important;
    height: 40px;
    width: 40px;
    color: #ffffff;
}

.userImg p:hover {
    background: rgb(57, 113, 236, 0.9) !important;
    color: #ffffff;
    cursor: pointer;
}

.userImg p svg {
    margin-right: 5px;
}

.muiCard.userImg img {
    width: 100%;
    height: 150px;

}

.userImg input:focus {
    outline: 0;
}

.userImgDelt, .editIcon {
    position: absolute !important;
    bottom: 0;
    right: 0;
    /*background: rgb(57, 113, 236, 0.5);*/
    width: 40px;
    height: 40px;
    border-radius: 0 !important;
    padding: 5px !important;
    color: #000000 !important;
    display: none !important;
    cursor: pointer !important;
}

.muiCardContent.userImg:hover .editIcon {
    background: rgb(230, 230, 230, 0.5);
    display: block !important;
    top: 0;
}

.muiCardContent.userImg:hover .userImgDelt {
    background: rgb(255, 255, 255, 0.5);
    display: block !important;
    color: red !important;
}

.forLogin.MuiPaper-root.MuiDrawer-paper.MuiDrawer-paperAnchorLeft {
    z-index: 99 !important;
    top: 39px !important;
    padding-bottom: 100px !important;
    padding-top: 100px !important;
}

.listStyle li {
    list-style: disc !important;
    padding: 0 15px;
}

.requiredHighlight, .MuiFormLabel-asterisk.MuiInputLabel-asterisk {
    color: red;
    font-size: 20px;
    margin-left: -2px;
}

button.menuButton:hover {
    border-bottom: 3px solid #01305F !important;
    background-color: transparent !important;
}

.MuiSelect-select {
    text-align: left !important;
}

.boxShadow {
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.11);
}

.forwebLoad {
    background: #ffffff;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 9999;
    display: flex;
    align-items: center;
}

.slide {
    animation: fadeIn 6s;
}

.MuiPagination-ul {
    float: right;
    width: auto;
}

.cusSelect .MuiInputLabel-formControl {
    line-height: 34px !important;
}

.cusSelect .MuiSelect-selectMenu {
    line-height: 24px !important;
}

.PhoneNumber-Disabled div {
    color: rgba(0, 0, 0, 0.38);
    pointer-events: none
}

.MuiDrawer-paperAnchorDockedLeft {
    border-right: 1px solid transparent !important;
    box-shadow: 0 2px 13px -3px #cfcfcf !important;
}

@media (max-width: 1920px) {
    .padding0Big {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}

.slide {
    animation: fadeIn 6s;
}

.lastItemMb20:last-child {
    margin-bottom: 20px !important;
}

#base {
    background: #01305F;
    display: inline-block;
    height: 90px;
    margin-left: 20px;
    margin-top: 0;
    position: absolute;
    width: 126px;
    right: 30px;
    top: 0;
    text-align: center;
    padding: 15px;
}

#base:after {
    border-bottom: 35px solid #01305F;
    border-left: 56px solid transparent;
    border-right: 68px solid transparent;
    content: "";
    height: 0;
    left: 0;
    position: absolute;
    bottom: -35px;
    width: 0;
    transform: rotate(180deg);

}

#chevron {
    position: absolute;
    text-align: center;
    padding: 0px;
    margin-bottom: 6px;
    height: 10px;
    width: 120px;
    bottom: -30px;
    z-index: 1;
    right: 0;
}

#chevron:before {
    content: '';
    position: absolute;
    top: 0;
    left: -6px;
    height: 100%;
    width: 58%;
    background: #3DDB93;
    transform: skew(0deg, 27deg);
}

#chevron:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 48%;
    background: #3DDB93;
    transform: skew(0deg, -33deg);
}

.kaiZenHero {
    width: 610px !important;

}

.kaiZenHero iframe {
    border: 4px solid #fff;
    border-radius: 10px;
}

.videoHeroOne {
    display: none;
    cursor: pointer;
    margin-bottom: 30px;
}

.f40 {
    font-size: 40px !important;
}

.heroCard {
    padding: 90px 30px 30px !important;
}

.arrow {
    width: 100px;
    height: 46px;
    display: flex;
    top: 76px;
    position: absolute;
}
.arrow.arrowAppoint{
    top: 20px !important;
    left: 160px !important;
}
.arrow:before {
    content: "";
    flex: 1;
    margin: 0 5px;
    background: url("./Images/arowPng.jpg") no-repeat;
    animation: a3 .5s infinite alternate;
}

@keyframes a3 {
    0% {
        transform: translate(-4px)
    }
    100% {
        transform: translate(4px)
    }
}

.MuiButton-contained.Mui-disabled {
    background: rgba(0, 0, 0, 0.12) !important;
}

.distributionOuterSpan span:nth-child(1) {
    height: 8px;
    color: #a3a3a3;
}

.distributionOuterSpan span:nth-child(1) span {
    background: transparent;
    height: auto;
    width: 80px;
    color: #a3a3a3;
    transform: rotate(0deg);
}

.distributionOuterSpan span:nth-child(1) span span {
    background: #424242;
    border-radius: 4px;
    text-align: center;
}

.distributionOuterSpan span:nth-child(2) {
    height: 8px;
}

.distributionOuterSpan span:nth-child(4) {
    height: 25px;
    width: 25px;
    margin-top: -9px !important;
    background: #3DDB93;
    border: 5px solid #3DDB93 !important;
}

.videoPopup {
    width: 100% !important;
    height: 450px !important;
}

.kaiZenMobile .MuiPaper-root.MuiDialog-paper {
    background: transparent !important;
    box-shadow: none !important;
}

.kaiZenMobile .MuiBackdrop-root {
    background-color: rgb(0 0 0 / 60%);
}

.learnMoreVideo, .kaiZenHero {
    width: 100% !important;
    height: 100% !important;
}

.kaiZenHero iframe {
    border: 5px solid #FFFFFF;
    height: 350px !important;
    margin: 0 auto;
}

.learnMoreVideo iframe {
    border: 10px solid #FFFFFF;
    width: 892px !important;
    height: 510px !important;
    margin: 0 auto;
}

[role="tooltip"] {
    z-index: 1300 !important;
}

.MuiIconButton-edgeEnd{
    margin-right: 10px !important;
}
f18 {
    font-size: 18px !important;
}

.f16 {
    font-size: 16px !important;
}

.f70 {
    font-size: 70px !important;
}

.overlay {
    background: rgba(0, 30, 59, .6);
    position: absolute;
    width: 100%;
    height: 100%;
    right: 0;
    left: 0;
    align-items: center;
    padding: 50px 0;
    display: flex;
}

@media (max-width: 1356px) {
    .textSize0 {
        display: flex;
    }

    .textSize0 button {
        font-size: 0;
    }

    .agentsImages span img {
        width: 140px;
    }
}

@media (max-width: 1278px) {
    .kaiZenHero iframe {
        border: none;
        width: 100% !important;
    }
}

@media (max-width: 891px) {
    .learnMoreVideo iframe {
        border: 10px solid transparent;
        width: 100% !important;
        height: 300px !important;
    }
}

@media (max-width: 767px) {

    .wSm-100 {
        width: 100%;
    }

    .actionItemsTabs button {
        width: 48%;
        margin: 5px 0;
        font-size: 0;
    }

    .actionItemsTabs button svg {
        font-size: 30px;
    }

    .floatRightSM {
        float: right !important;
    }

    .MuiIconButton-edgeStart {
        margin-left: 10px !important;
    }


}


/*===================================OLD======================*/
@media (min-width: 1500px) and (max-width: 5000px) {
    .video-container {
        min-height: 90vh;
        margin-top: 0;
    }
}

@media (min-width: 1256px) {
    .mt40md {
        margin-top: 40px !important;
    }

    img.scrollIcon {
        left: 0;
    }

}

@media (max-width: 1256px) {
    .video-mask {
        padding-top: 30px;
    }

}

@media (max-width: 1257px) {
    img.scrollIcon {
        display: none;
    }


    .LogoList li:last-child {
        width: 100% !important;
        text-align: center;
    }

    .LogoList li:last-child img {
        width: auto;
        margin: 0 auto;
    }

    .xsOrderLast {
        order: 3;
    }

    .title::before {
        top: -15px;
    }

    .floatLeft.floatInherit {
        float: inherit !important;
    }

    .textCenterXs {
        text-align: center !important;
    }

    .pd10Xs {
        padding: 0 10px;
    }

    .whiteBgXs {
        background: #ffffff !important;
    }

    .VideosHeight {
        height: 1120px !important;
    }

    .video-container video {
        position: relative;
        height: 1120px !important;
    }

    .video-container .FirstVideos video {
        position: relative;
        height: 600px !important;
    }

    .video-container {
        height: 1120px;
        flex-direction: column-reverse;
        margin-top: 100px;

    }

    html, body, .layoutWrap {
        overflow-x: unset;
    }

    .w100sm {
        width: 100%
    }

    .mr0sm {
        margin-right: 0 !important;
    }

    .tabTwoVideo {
        height: 600px !important;
    }

    .returnRateBox {
        margin-top: 20px;
    }

    .pdsM {
        padding: 120px 15px 0 !important;
    }

    #graphDiv {
        padding-bottom: 0 !important;
    }

    .MuiPaper-root.MuiDrawer-paper.MuiDrawer-paperAnchorLeft {
        top: 105px !important;

    }

    .MuiCollapse-root.MuiCollapse-entered {
        border-bottom: 1px solid #cfcfcf !important;
    }

    .arrow {
        display: none;
    }
}

@media (max-width: 1024px) {
    #base {
        position: relative;
        top: -30px;
        height: 90px;
        float: right;
        right: 0;
    }

    #base:after {
        bottom: -34px;
    }

    .heroCard {
        padding: 30px !important;
    }
}
@media (max-width: 992px) {
    .colorBgsection {
        padding: 15px;
    }

    .colReverse {
        -webkit-flex-direction: column-reverse;
        flex-direction: column-reverse;

    }

    .colMarginNone {
        margin: 0 !important;
        width: 100% !important;
    }

    .pdXsScreenNone {
        padding-left: 0 !important;
        padding-right: 0 !important;

    }

    .pdXsScreenBottom {
        padding-bottom: 30px !important;
    }

    .pdXsScreen20 {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }

    .pdXsScreenTop0 {
        padding-top: 0 !important;
    }

    .displayNoneSm {
        display: none !important;
     }
    .MuiPaper-root.MuiDrawer-paper.MuiDrawer-paperAnchorLeft {
        top: 150px !important;
    }

    .sectionFindAgent {
        min-height: 450px;
    }

    .agentsImages {
        display: none;
    }

    .kaiZenHero {
        width: 100% !important;
        height: 100% !important;
        display: none;
    }

    .videoHeroOne {
        display: inline-flex;
        align-items: center;
    }
    .overlay{
        position: relative;
    }

}

@media (max-width: 767px) {
    .MuiPaper-root.MuiDrawer-paper.MuiDrawer-paperAnchorLeft {
        top: 110px !important;

    }

    .margin20XS {
        margin: 0 0 20px !important;
    }
    .forRealButton button {
        padding: 6px 8px !important;
    }
    .returnRateBox{
        margin-top: 20px;
    }
    .pdsM{
        padding: 120px 0 0 !important;
    }
    .mt40sm{
        margin-top: 40px !important;
    }
    .pdsM {
        padding: 120px 0 0 !important;
    }
    .floatInherit{
        float: none !important;
    }
    .MuiButton-outlinedSizeLarge{
        padding: 15px 26px !important;
    }
    .textLeftXs{
       text-align: left !important;
    }
    .displayInlinexs {
        display: inline !important;
    }
}

/*===================================OLD======================*/
@media (min-width: 1500px) and (max-width: 5000px) {
    .video-container {
        min-height: 90vh;
        margin-top: 0;
    }
}

